import { useState, useEffect } from "react";
import Landing from "./Components/Landing/Landing";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./Components/About/About";
import Signup from "./Components/SignUp/Signup";
import Community from "./Components/Community/Community";
import ListCommunity from "./Components/ListCommunity/ListCommunity";
import Discover from "./Components/Discover/Discover";
function App() {
  // const [loginResponse, setLoginResponse] = useState();
  // useEffect(async () => {}, []);
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/about' element={<About />} />
        <Route path='sign-up' element={<Signup />} />
        <Route path='/community/:contractAddress' element={<Community />} />
        <Route path='/list-community' element={<ListCommunity />} />
        <Route path='/discover' element={<Discover />} />
      </Routes>
    </Router>
  );
}
export default App;
