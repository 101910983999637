import React from "react";
import defaultBanner from "../../assets/metamountaineers.png";
import toast, { Toaster } from "react-hot-toast";
function UserCard({ circleStateless, EthreumWalletAddress }) {
  const NODE_URL = "https://node.deso.org/api/v0";

  let cover = defaultBanner;
  let imgURL = `${NODE_URL}/get-single-profile-picture/${circleStateless.ProfileEntryResponse.PublicKeyBase58Check}?fallback=https://diamondapp.com/assets/img/default_profile_pic.png`;
  try {
    for (const [key, value] of Object.entries(
      circleStateless.ProfileEntryResponse.ExtraData
    )) {
      if (key === "LargeProfilePicURL" && value !== "") {
        imgURL = value;
      }
      if (key === "FeaturedImageURL" && value !== "") {
        cover = value;
      }
    }
  } catch (e) {
    //console.log(e);
  }

  //get all text till newline from circleStateless.ProfileEntryResponse.Description
  const description =
    circleStateless.ProfileEntryResponse.Description.split("/n")[0];

  return (
    <>
      <div className='rounded-md secondaryBg secondaryBorder secondaryTextColor overflow-hidden border relative z-0 border-gray-800 md:w-3/5 my-1'>
        <Toaster />
        <div className='flex flex-col'>
          <div
            className=' h-32 md:h-48 overflow-hidden'
            style={{
              position: "relative",
              backgroundImage: `url(${cover !== "" ? cover : defaultBanner})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}></div>

          <div className='flex flex-col items-center justify-center -mt-20 relative z-10'>
            <img
              src={imgURL}
              className='rounded-full subHeader border-4 border-white dark:border-[#212126] w-22 h-24 md:w-32 md:h-32 '
              alt={circleStateless.ProfileEntryResponse.Username}
            />
            <div className='flex items-center flex-col mt-1'>
              <a
                className='font-semibold text-2xl text-clip overflow-hidden  underline text-blue-400'
                href={`https://diamondapp.com/u/${circleStateless.ProfileEntryResponse.Username}`} 
                target="_blank"
                >
                {circleStateless.ProfileEntryResponse.Username}
              </a>
            </div>
          </div>
        </div>
        <div className='p-6 pt-2 flex justify-center flex-col items-center'>
          <div className='w-full pt-2 h-[6.5rem] overflow-hidden text-gray-100'>
            {description}
          </div>
          <button
            className='text-gray-300 bg-[#27273d] py-1 px-3 text-center rounded-md mt-2 hover:bg-[#32324e] text-sm sm:text-base'
            onClick={() => {
              navigator.clipboard.writeText(EthreumWalletAddress);
              toast.success("Ethereum address copied to clipboard!");
            }}>
            {EthreumWalletAddress}
          </button>
        </div>
      </div>
    </>
  );
}

export default UserCard;
