import React from "react";
import logo from "../assets/logo.svg";
import Loader from "./Loader";
import Deso from "deso-protocol";
import DesoApi from "../tools/desoAPI";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
const deso = new Deso();
const da = new DesoApi();

export default function Navbar({ showLogin }) {
  const [loginResponse, setLoginResponse] = useState(null);
  const navigate = useNavigate();
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [loadingCreators, setIsLoadingCreators] = useState(true);
  const [userList, setUserList] = useState([]);
  const [isLogging, setIsLogging] = useState(false);

  useEffect(() => {
    setIsPageLoading(true);
    // wait for 3 seconds

    const loggedInPublicKey = localStorage.getItem("loggedInUser");
    console.log(loggedInPublicKey);
    if (loggedInPublicKey) {
      //const loggedInUser = await deso.getUser(loggedInPublicKey);
      setLoggedInUser(loggedInPublicKey.toString());
    }

    setIsPageLoading(false);
  }, []);

  const handleLogOut = async () => {
    if (isLoggingOut) {
      return;
    }
    setIsLoggingOut(true);
    //clear local storage
    let loggedInUser = localStorage.getItem("loggedInUser");
    localStorage.removeItem("loggedInUser");
    let jwt = await deso.identity.getJwt(undefined);
    const response = await da.logoutUser({
      jwt: jwt,
      publicKeyBase58Check: loggedInUser,
    });
    console.log(response);
    setIsLoggingOut(false);
    //reload page
    window.location.reload();
  };

  const handleLogin = () => {
    navigate("/sign-up");
  };

  return (
    <div className='gradient leading-relaxed tracking-wide flex flex-col bg-gray-900'>
      <nav id='header' className='w-full z-30 top-0  py-1 '>
        <div className='w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-2 '>
          <div className='flex items-center'>
            <a
              href='/'
              className='no-underline flex hover:no-underline font-bold text-2xl lg:text-4xl'>
              <img src={logo} alt='logo' className='h-16 w-16' />
              <span className='mx-2 my-2 text-white'> Group Follow </span>
            </a>
          </div>
          {showLogin && (
            <div className='' id='nav-content'>
              {isLogging ? (
                <div className='mx-auto px-10'>
                  <Loader />
                </div>
              ) : loggedInUser !== null ? (
                <div className='mx-auto px-10 flex flex-col items-center'>
                  <button>
                    <img
                      className='h-12 w-12 rounded-full'
                      src={`https://node.deso.org/api/v0/get-single-profile-picture/${loggedInUser}?fallback=https://diamondapp.com/assets/img/default_profile_pic.png`}
                    />
                  </button>
                  {/*  add a click to log out drop down buttton*/}
                  <div className='flex flex-col items-center justify-center my-1'>
                    <button
                      className='border-gray-100 border-2 text-gray-100 font-bold py-2 px-4 rounded-full hover:bg-slate-700'
                      onClick={() => {
                        handleLogOut();
                      }}>
                      Logout
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  onClick={handleLogin}
                  target={"_blank"}
                  id='navAction'
                  className='mx-auto gradient2 lg:mx-0 hover:underline text-gray-100 font-extrabold rounded mt-4 lg:mt-0 py-3 px-8 shadow bg-green-500 '>
                  Get Started
                </button>
              )}
            </div>
          )}
        </div>
      </nav>
    </div>
  );
}
