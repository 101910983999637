import React from "react";
import Navbar from "../Navbar";
import Footer from "../Landing/Footer";
import { useState } from "react";
import DesoApi from "../../tools/desoAPI";

import toast, { Toaster } from "react-hot-toast";
export default function ListCommunity() {
  const [isSending, setIsSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const da = new DesoApi();
  const handleMessage = async (e) => {
    //prevent page reload due to form submit

    e.preventDefault();
    const projectName = document.getElementById("projectName").value;
    const contractAddress = document.getElementById("contractAddress").value;
    const description = document.getElementById("description").value;
    const contactVal = document.getElementById("contact").value;
    if (
      projectName.length > 0 &&
      contractAddress.length > 0 &&
      description.length > 0 &&
      contactVal.length > 0
    ) {
      setIsSending(true);
      try {
        const response = await da.listCommunity(
          contractAddress,
          projectName,
          `${description} Contact: ${contactVal}`
        );
        if (response.status == 200) {
          toast.success("Listing Request Sent Successfully");
          document.getElementById("projectName").value = "";
          document.getElementById("contractAddress").value = "";
          document.getElementById("description").value = "";
        }
        setIsSending(false);
      } catch (err) {
        console.log(err);
        setIsSending(false);
      }
    }
    //make a post request to the server
  };
  return (
    <div>
      <>
        <Navbar showLogin={true} />
        <Toaster />
        <div className='pt-32 sm:pt-24 bg-gray-900 '>
          <div className='sm:w-3/4 md:w-3/5 justify-center mx-auto px-3'>
            <form className='flex flex-col' action='#' onSubmit={handleMessage}>
              <label className='text-gray-100'>Project Name</label>
              <input
                type='text'
                name='username'
                id='projectName'
                className='border-gray-800 border-2 rounded-md my-2 px-2 bg-gray-700 active:outline-none focus:outline-none py-2 text-white'
                placeholder='Project Name'
                required
              />

              <label className='text-gray-100'>NFT Contract Address</label>
              <input
                type='text'
                name='username'
                id='contractAddress'
                className='border-gray-800 border-2 rounded-md my-2 px-2 bg-gray-700 active:outline-none focus:outline-none py-2 text-white'
                placeholder='NFT Contract Address (Ethereum)'
                required
              />

              <label className='text-gray-100'>Project Description</label>
              <textarea
                name='message'
                id='description'
                className='border-gray-800 bg-gray-700 border-2 rounded-md my-2 px-2 py-2 text-white active:outline-none focus:outline-none'
                placeholder='Your Message'
                style={{ height: "200px" }}
                required></textarea>

              <label className='text-gray-100'>Email or Twitter Link</label>
              <input
                type='text'
                name='contact'
                id='contact'
                className='border-gray-800 border-2 rounded-md my-2 px-2 bg-gray-700 active:outline-none focus:outline-none py-2 text-white'
                placeholder='Email or Twitter Link'
                required
              />
              <div className='mx-auto my-3'>
                <button
                  type='submit'
                  className=' rounded-2 px-6 py-1 rounded  shadow-md text-gray-900 bg-white  border border-gray-200 hover:bg-gray-100  focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200  dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-70 '>
                  {isSending ? "Sending..." : "Request Listing"}
                </button>
              </div>
              {success ? (
                <div className='text-center text-green-500'>
                  ✔️Message Sent Succesfully!
                </div>
              ) : null}
            </form>
          </div>
        </div>

        <Footer />
      </>
    </div>
  );
}
