import React from "react";
import metamountainers from "../../assets/metamountaineers.png";
import augumentedLabs from "../../assets/augumentedLabs.png";
import { useNavigate } from "react-router";
export default function FeaturedCommunity() {
  const navigate = useNavigate();
  const featuredComms = [
    {
      Name: "Metamountainers",
      coverImage: metamountainers,
      description:
        "Meta Mountaineers are an NFT collection on the Ethereum Blockchain that are the pioneers of NFT verified Follow for Follow!",
      isLive: true,
      contract:
        "0x8c01f90d54af84394af14e673b13802a714d72e8",
    },
  ];
  return (
    <div className=' justify-center bg-gray-900 py-16'>
      <h1 className='text-white text-4xl text-center'>Featured Communities</h1>
      <div className='w-full mb-4'>
        <div className='h-1 mx-auto bg-white w-64 opacity-25 my-0 py-0 rounded-t'></div>
      </div>
      <div className='flex justify-center flex-col items-center py-12 space-y-16'>
        {featuredComms.map((community, index) => {
          return (
            <div
              className='flex flex-col sm:flex-row items-center space-x-4'
              key={index}>
              <div
                style={{
                  backgroundImage: `url(${community.coverImage})`,
                  backgroundSize: "cover",
                }}
                className='w-80 h-64 rounded-xl'></div>
              <div className='flex flex-col'>
                <h2 className='text-white text-2xl font-semibold underline'>
                  {community.Name}
                </h2>
                <p className='text-gray-300 text-lg my-4 w-96 sm:w-128 '>
                  {community.description}
                </p>
                <div className='flex '>
                  <button
                    className={` text-white px-5 py-3 rounded-md  ${
                      !community.isLive
                        ? "bg-gray-400"
                        : "bg-green-500 hover:bg-green-600"
                    }`}
                    onClick={() => {
                      if (community.isLive) {
                        navigate(`/community/${community.contract}`);
                      }
                    }}>
                    {community.isLive ? "View Community" : "Coming Soon!"}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className='flex justify-center flex-col items-center'>
        <button
          className='bg-blue-500 text-white rounded-lg px-9 py-4 text-xl my-6 hover:bg-blue-600'
          onClick={() => navigate("/discover")}>
          Discover Communities
        </button>
        <button
          className='border-blue-500 border-2 text-white rounded-lg px-6 py-4 text-xl my-6 hover:bg-blue-600'
          onClick={() => navigate("/list-community")}>
          List Your NFT Community
        </button>
      </div>
    </div>
  );
}
