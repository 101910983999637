import React from "react";
import Footer from "../Landing/Footer";
import toast, { Toaster } from "react-hot-toast";
import { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Deso from "deso-protocol";
import DesoApi from "../../tools/desoAPI";
import Loader from "../Loader";
import { ethers } from "ethers";
import { useNavigate } from "react-router";

export default function Signup() {
  const navigate = useNavigate();
  const deso = new Deso();
  const da = new DesoApi();
  const [loginResponse, setLoginResponse] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isLogging, setIsLogging] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [hasProfile, setHasProfile] = useState(false);
  const [userName, setUserName] = useState("");
  const [level2Login, setLevel2Login] = useState(false);

  const [level2LoginKey, setLevel2LoginKey] = useState("");
  const [hasDerivedKeyLogin, setHasDerivedKeyLogin] = useState(false);
  const [metamaskAddress, setMetamaskAddress] = useState(null);

  const [showBraveWarning, setShowBraveWarning] = useState(false);

  const signMessage = async ({ message }) => {
    try {
      console.log({ message });
      if (!window.ethereum)
        toast.error("No crypto wallet found. Please install it.");

      await window.ethereum.send("eth_requestAccounts");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signature = await signer.signMessage(message);
      const address = await signer.getAddress();

      return {
        message,
        signature,
        address,
      };
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    async function initPage() {
      setIsPageLoading(true);
      // wait for 3 seconds

      const loggedInPublicKey = localStorage.getItem("loggedInUser");
      console.log(loggedInPublicKey);
      if (loggedInPublicKey) {
        const request = {
          PublicKeysBase58Check: [loggedInPublicKey],
        };
        const response = await deso.user.getUserStateless(request);
        const userInfo = await da.getUserInfo(loggedInPublicKey);
        if (userInfo.spendingLimitHex) {
          setHasDerivedKeyLogin(true);
        }
        if (userInfo.EthPublicKey) {
          setMetamaskAddress(userInfo.EthPublicKey);
        }
        setHasProfile(response.UserList[0].ProfileEntryResponse !== null);
        setUserName(response.UserList[0].ProfileEntryResponse.Username);
        setLoggedInUser(loggedInPublicKey.toString());
        setLevel2Login(true);
      }
      try {
        if (navigator.brave && (await navigator.brave.isBrave())) {
          setShowBraveWarning(true);
        }
      } catch (err) {
        console.log(err);
      }

      setIsPageLoading(false);
    }
    initPage();
  }, []);

  const handleLogin = async () => {
    const loginRes = await deso.identity.login(2);
    console.log(loginRes);
    if (loginRes) {
      setLevel2LoginKey(loginRes.key);
      setLevel2Login(true);
    }
    console.log(loginRes);
  };

  const handleDeriveLogin = async () => {
    if (isLogging) return;

    // await for 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const payload = {
      expirationDays: 365,
      transactionSpendingLimitResponse: {
        GlobalDESOLimit: 0.2 * 1e9,
        TransactionCountLimitMap: {
          AUTHORIZE_DERIVED_KEY: 2,
          FOLLOW: 100000000,
        },
      },
    };
    const user = await deso.identity.derive(payload);
    try {
      if (user.publicKeyBase58Check !== level2LoginKey) {
        toast.error(
          "Please allow follow permision with the same account you used to login"
        );
        return;
      }
      console.log(user);

      const request = {
        PublicKeysBase58Check: [user.publicKeyBase58Check],
      };
      const response2 = await deso.user.getUserStateless(request);
      const profileInfo = response2.UserList[0].ProfileEntryResponse !== null;
      setUserName(response2.UserList[0].ProfileEntryResponse.Username);
      setHasProfile(profileInfo);

      if (!profileInfo) {
        setLoggedInUser(user.publicKeyBase58Check);
        return;
      }
      setIsLogging(true);

      const response = await da.initDerivedKeys(user);
      if (response.insertion) {
        localStorage.setItem("loggedInUser", user.publicKeyBase58Check);

        setLoggedInUser(user.publicKeyBase58Check);
      }
      else{
        toast.error(response.remark);
      }
      console.log(response);

      setLoginResponse(JSON.stringify(user, null, 2));
      setIsLogging(false);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleMetamaskLogin = async () => {
    const sig = await signMessage({
      message: `By Signing this message, you are verifying your DeSo Identity with Metamask. DESO PUBLIC KEY: ${loggedInUser}`,
    });
    if (sig) {
      const jwt = await deso.identity.getJwt(undefined);
      const validatinoResponse = await da.verifyMetamaskWithDeso(
        loggedInUser,
        jwt,
        sig
      );
      if (validatinoResponse.insertion) {
        toast.success(validatinoResponse.remark);
      } else {
        toast.error(validatinoResponse.remark);
      }
      //await for 2 seconds
      await new Promise((resolve) => setTimeout(resolve, 2000));
      window.location.reload();
    }
  };

  return (
    <div>
      <>
        <Navbar showLogin={false} />
        <Toaster />
        {isPageLoading ? (
          <div className='flex justify-center items-center h-screen bg-gray-900'>
            <Loader />
          </div>
        ) : (
          <div className='pt-32 sm:pt-24 bg-gray-900 min-h-screen'>
            {showBraveWarning && (
              <div className=' text-gray-100  mx-auto flex justify-center items-center'>
                <p className='text-center bg-gray-800 px-3 py-2 border-l-2 border-yellow-500'>
                  <span className='font-bold text-yellow-500'>Note:</span> If
                  you have brave shields enabled, make sure to disable it for
                  GroupFollow.
                </p>
              </div>
            )}
            <div className='container px-3 mx-auto flex flex-wrap flex-col  items-center '>
              <div>
                {!loggedInUser && level2Login && !hasDerivedKeyLogin && (
                  <div className='flex justify-center items-center flex-col my-4'>
                    <p className='text-gray-100 text-xl mb-4'>
                      Now Allow Group Follow to follow users on DeSo on your
                      behalf. Make sure to do this with same account you used to
                      login before.
                    </p>
                    <button
                      target={"_blank"}
                      onClick={handleDeriveLogin}
                      id='navAction'
                      className='mx-auto gradient2 lg:mx-0 hover:underline text-gray-100 font-extrabold rounded mt-4 lg:mt-0 py-3 px-8 shadow bg-green-500 '>
                      Allow Follow Access
                    </button>
                    {isLogging && (
                      <div className='flex justify-center items-center'>
                        <Loader />
                      </div>
                    )}
                  </div>
                )}

                {!loggedInUser && !level2Login && (
                  <div className='flex justify-center items-center flex-col my-4'>
                    <p className='text-gray-100 text-xl mb-4'>
                      Please Login with DeSo.
                    </p>
                    <button
                      target={"_blank"}
                      onClick={handleLogin}
                      id='navAction'
                      className='mx-auto gradient2 lg:mx-0 hover:underline text-gray-100 font-extrabold rounded mt-4 lg:mt-0 py-3 px-8 shadow bg-green-500 '>
                      Login with DeSo
                    </button>
                    {isLogging && (
                      <div className='flex justify-center items-center'>
                        <Loader />
                      </div>
                    )}
                  </div>
                )}

                {loggedInUser && !hasProfile && (
                  <p className='leading-normal text-2xl mb-8 text-white'>
                    You haven't created profile on DeSo yet. Please create one
                    at{" "}
                    <span>
                      <a
                        href={`https://diamondapp.com`}
                        target='_blank'
                        className='text-blue-500'>
                        Diamond App
                      </a>
                    </span>
                    <br></br>
                    After creating profile, reload this page!
                  </p>
                )}
                {loggedInUser && hasProfile && !metamaskAddress && (
                  <p className='leading-normal text-lg mb-8 text-white flex items-center '>
                    Logged in as{"   "}
                    <span>
                      <img
                        src={`https://diamondapp.com/api/v0/get-single-profile-picture/${loggedInUser}?fallback=https://diamondapp.com/assets/img/default_profile_pic.png`}
                        className='ml-2 w-10 h-10 rounded-full'
                        alt={loggedInUser}
                      />
                    </span>
                    {userName}
                  </p>
                )}
              </div>

              {loggedInUser && !metamaskAddress && hasProfile && (
                <div className='flex flex-col justify-center items-center'>
                  <p className='text-gray-100 text-xl mb-4'>
                    Now Connect your Metamask Wallet through which you want to
                    verify your NFT assets
                  </p>
                  <button
                    onClick={handleMetamaskLogin}
                    target={"_blank"}
                    id='navAction'
                    className='mx-auto gradient2 lg:mx-0 hover:underline text-gray-100 font-extrabold rounded mt-4 lg:mt-0 py-3 px-8 shadow bg-green-500 '>
                    Verify Metamask Wallet
                  </button>
                </div>
              )}

              {loggedInUser && metamaskAddress && hasDerivedKeyLogin && (
                <div className='flex justify-center items-center flex-col my-4'>
                  <p className='text-gray-100 text-xl mb-4'>
                    You have successfully verified your Metamask Wallet with
                    DeSo. Groupfollow can now follow users on your behalf.
                  </p>

                  <p className='leading-normal text-lg mt-4 mb-1 text-white flex items-center '>
                    DeSo Profile{"   "}
                    <span>
                      <img
                        src={`https://diamondapp.com/api/v0/get-single-profile-picture/${loggedInUser}?fallback=https://diamondapp.com/assets/img/default_profile_pic.png`}
                        className='ml-2 w-10 h-10 rounded-full'
                        alt={loggedInUser}
                      />
                    </span>
                    {userName}
                  </p>
                  <p className='leading-normal text-lg mb-8 text-white flex items-center '>
                    {`Your Metamask Wallet Address is ${metamaskAddress}`}
                  </p>

                  <button
                    onClick={() => {
                      navigate("/discover");
                    }}
                    target={"_blank"}
                    id='navAction'
                    className='mx-auto gradient2 lg:mx-0 hover:underline text-gray-100 font-extrabold rounded mt-4 lg:mt-0 py-3 px-8 shadow bg-blue-500 '>
                    Discover Communites to Follow
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        <Footer />
      </>
    </div>
  );
}
