import React from "react";

export default function Workings() {
  return (
    <div className='min-h-screen bg-gray-900 bg-gray-900 bg-gray-900 '>
      <div className=' text-white text-center text-4xl'>How it Works</div>
      <div className=' my-9 w-full  flex justify-center'>
        <p className=' text-white text-lg w-3/4 text-center'>
          Group Follow is an automated follow for follow tool that allows NFT
          owners to follow and get automatically followed by the members of the
          NFT community. <br></br>The following and the community is on-chain
          (Deso Blockchain). DeSo Blockchain is the social layer of web3 that
          allows communities and users to own their content permissionlessly.
          <br></br>
          To use group follow, a user firstly need to have an on-chain profile
          on DeSo. It can be created for free on{" "}
          <a
            href='https://diamondapp.com'
            target={"_blank"}
            className='text-blue-200 underline'>
            DiamondApp.
          </a>
          . After having a DeSo Profile, the user can log in to Group Follow.
          While logging with Deso Identity on GroupFollow, the user provides us
          an infinite follow transaction permission. <br></br>
          After DeSo login, the user is required to connect their Metamask
          wallet to verify NFT ownership.
          <br></br>
          That's it! The user can then sync their follow for follow in any NFT
          community
        </p>
      </div>
    </div>
  );
}
