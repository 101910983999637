import React from "react";
import Navbar from "../Navbar";
import Workings from "./Workings";

export default function About() {
  return (
    <div>
      <Navbar />
      <Workings/>
    </div>
  );
}
