import React from "react";
import Navbar from "../Navbar";
import Footer from "../Landing/Footer";
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import DesoApi from "../../tools/desoAPI";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import Loader from "../Loader";
export default function Discover() {
  const da = new DesoApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [communities, setCommunities] = useState(null);

  useEffect(() => {
    async function fetchCommunities() {
      const res = await da.discoverCommunities(50);
      if (res.status == 200) {
        setCommunities(res.communities);
      } else {
        toast.error("Something went wrong, Please reload the page");
      }
      setLoading(false);
    }
    if (!communities) {
      fetchCommunities();
    }
  }, []);

  return (
    <div className=''>
      <>
        <Toaster />
        <Navbar showLogin={true} />
        <div className='pt-32 sm:pt-24 bg-gray-900 min-h-screen'>
          {loading ? (
            <div className='flex justify-center'>
              <Loader />
            </div>
          ) : (
            communities &&
            communities.map((community, index) => {
              return (
                <div
                  className='flex flex-col sm:flex-row items-center space-x-4 justify-center mt-10'
                  key={index}>
                  <div
                    style={{
                      backgroundImage: `url(https://images.deso.org/${community.bannerURL}.webp)`,
                      backgroundSize: "cover",
                    }}
                    className='w-80 h-64 rounded-xl'></div>
                  <div className='flex flex-col'>
                    <h2 className='text-white text-2xl font-semibold underline'>
                      {community.projectName}
                    </h2>
                    <p className='text-gray-300 text-lg my-4 w-96 sm:w-128 '>
                      {community.projectDescription}
                    </p>
                    <div className='flex '>
                      <Link
                        className=' text-white px-5 py-3 rounded-md bg-green-500 hover:bg-green-600 mr-4 '
                        to={`/community/${community.contractAddress.slice(3)}`}>
                        View Communitity
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>

        <Footer />
      </>
    </div>
  );
}
