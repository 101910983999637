import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Navbar from "../Navbar";
import Footer from "../Landing/Footer";
import Deso from "deso-protocol";
import DesoApi from "../../tools/desoAPI";
import Loader from "../Loader";
import UserCard from "./UserCard";
import toast, { Toaster } from "react-hot-toast";
export default function Community() {
  const params = useParams();
  const contractAddress = params.contractAddress;
  const deso = new Deso();
  const da = new DesoApi();
  const [loading, setLoading] = useState(true);
  const [userStateless, setUserStateless] = useState(null);
  const [communityMembers, setCommunityMembers] = useState([]);
  const [contractInfo, setContractInfo] = useState(null);
  const [hasCommunity, setHasCommunity] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);

  useEffect(() => {
    async function fetchCommunity() {
      const contractInfoRes = await da.getContractInfo(contractAddress);

      setContractInfo(contractInfoRes.contractInfo);
      const communityMembersRes = await da.getCommunity(contractAddress);
      if (communityMembersRes.remark != "No users found.") {
        setHasCommunity(true);
      } else {
        setHasCommunity(false);
        setLoading(false);
        return;
      }

      let communityArray = [];
      console.log(communityMembersRes.community);
      let userList = [];
      for (const [key, value] of Object.entries(
        communityMembersRes.community
      )) {
        userList.push(value);
        communityArray.push({ publicKey: value, EthereumAddress: key });
      }
      setCommunityMembers(communityArray);
      const request = {
        PublicKeysBase58Check: userList,
        SkipForLeaderboard: true,
      };
      const response2 = await deso.user.getUserStateless(request);
      setUserStateless(response2.UserList);
      setLoading(false);
    }
    if (!userStateless && contractAddress) {
      console.log(contractAddress);
      fetchCommunity();
    }
  }, [contractAddress]);

  const handleSyncFollowForFollow = async () => {
    if (isSyncing) return;
    setIsSyncing(true);

    const toastId =  toast.loading("Syncing follow for follow...");


    
   
    
    let jwt = "";
    try {
      jwt = await deso.identity.getJwt(undefined);
    } catch (e) {
      toast.error("Please login to sync your follow for follow");
      setIsSyncing(false);
      toast.dismiss(toastId);
      return;
    }

    try {
      let loggedInUser = localStorage.getItem("loggedInUser");
      if (!loggedInUser) {
        toast.dismiss(toastId);
        toast.error("Please login to sync your follow for follow");
        return;
      }
      const response = await da.subscribeCommunity(
        contractAddress,
        loggedInUser,
        jwt
      );
      if (response.status == 200) {
        toast.dismiss(toastId);
        toast.success("Follow for follow synced successfully");
      } else {
        toast.dismiss(toastId);
        toast.error(response.remark);
      }
      setIsSyncing(false);
      toast.dismiss(toastId);
    } catch (e) {
      toast.dismiss(toastId);
      toast.error("Something went wrong");
      setIsSyncing(false);
    }
  };

  return (
    <div>
      <>
        <Navbar showLogin={true} />

        <div className='pt-24 sm:pt-20 bg-gray-900 min-h-screen'>
          <Toaster />
          {loading ? (
            <div className='flex justify-center'>
              <Loader />
            </div>
          ) : (
            <div className='container px-3 mx-auto flex flex-wrap flex-col items-center '>
              <div className='flex flex-col sm:flex-row items-center space-x-4'>
                <div
                  style={{
                    backgroundImage: `url(https://images.deso.org/${contractInfo.bannerURL}.webp)`,
                    backgroundSize: "cover",
                  }}
                  className='w-80 h-64 rounded-xl'></div>
                <div className='flex flex-col text-center'>
                  <h2 className='text-white text-2xl font-semibold '>
                    {contractInfo.projectName}
                  </h2>
                  <p className='text-gray-300 text-lg my-4 w-96 sm:w-128 '>
                    {contractInfo.projectDescription}
                  </p>
                </div>
              </div>
              <button
                className='bg-blue-500 text-white rounded-lg px-6 py-4 text-xl my-6 hover:bg-blue-600'
                onClick={handleSyncFollowForFollow}>
                Sync Follow-for-Follow
              </button>
              {hasCommunity && (
                <p className='text-gray-100 text-2xl my-10 '>
                  DeSo Users who own the NFTs 👀{" "}
                </p>
              )}
              {communityMembers &&
                hasCommunity &&
                communityMembers.map((member) => {
                  return (
                    <UserCard
                      circleStateless={userStateless.find(
                        (c) => c.PublicKeyBase58Check == member.publicKey
                      )}
                      EthreumWalletAddress={member.EthereumAddress}
                    />
                  );
                })}

              {!hasCommunity && (
                <p className='text-gray-100 text-2xl my-10 '>
                  No users found in this community
                </p>
              )}
            </div>
          )}
        </div>

        <Footer />
      </>
    </div>
  );
}
